export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home'),
    meta: {}
  },
  {
    path: '/donate',
    name: 'donate',
    component: () => import('@/views/Public/Donate'),
    meta: {
      title: 'Donate'
    }
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import('@/views/Public/Faq'),
    meta: {
      title: 'FAQ'
    }
  },
  {
    path: '/faq/notary',
    name: 'notary',
    component: () => import('@/views/Public/Notary'),
    meta: {
      title: 'Notary'
    }
  },
  {
    path: '/minor-children',
    name: 'minor-children',
    component: () => import('@/views/Public/MinorChildren'),
    meta: {
      title: 'Minor Children'
    }
  },
];
