import { authAxios } from '@/api';
import { ROOT_URL, API_VER } from '@/app/constants';

const API_CONTROLLER = 'user';

export class UserApi {
  static getUserProfile() {
    const url = `${ROOT_URL}/${API_VER}/${API_CONTROLLER}/profile`;
    return authAxios.get(url);
  }

  static searchUsers(userSearch) {
    const url = `${ROOT_URL}/${API_VER}/${API_CONTROLLER}/search`;
    return authAxios.post(url, userSearch);
  }
}
