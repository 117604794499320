import Vue from 'vue';
import log from 'loglevel';
import router from '@/router';
import getGlobalSettings from '@/setup/globalSettings';
import VueKeyCloak from '@dsb-norge/vue-keycloak-js';
import { KeycloakInstance, VueKeycloakOptions } from '@dsb-norge/vue-keycloak-js/dist/types';
import { Route } from 'vue-router';


function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function keycloakOfficialAuth() {

  // https://github.com/dsb-norge/vue-keycloak-js
  // https://www.keycloak.org/docs/latest/securing_apps/index.html#_javascript_adapter

  const globalSettings = getGlobalSettings();

  const rft = localStorage.getItem('refresh_token');

  const options: VueKeycloakOptions = {
    init: {
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: window.location.origin + '/oidc/silent-sign-in.html',
      flow: 'standard',
      refreshToken: rft ?? undefined,
    },
    logout: {
      redirectUri: window.location.origin
    },
    config: {
      url: globalSettings.auth.authority,
      clientId: globalSettings.auth.clientId ?? '',
      realm: globalSettings.auth.realm ?? '',
    },
    onReady: (keycloakInstance) => {
      log.debug('Keycloak ready', keycloakInstance);
      localStorage.setItem('refresh_token', keycloakInstance.refreshToken ?? '');
    },
    onInitError: error => {
      log.debug('Keycloak init error', error);
    },
    onInitSuccess(authenticated: boolean) { // keycloak?: Keycloak.KeycloakInstance, VueKeycloak?: VueKeycloakInstance
      log.debug('Keycloak init success', { authenticated });
    },
    onAuthRefreshSuccess: (keycloak: KeycloakInstance) => {
      log.debug('Keycloak refresh success', { keycloak }, keycloak.refreshToken);
      localStorage.setItem('refresh_token', keycloak.refreshToken ?? '');
    },
    onAuthRefreshError: keycloak => {
      // this means that the refresh failed for some reason
      // in my test case, SSO Session Max was exceeded
      log.debug('Keycloak refresh error', { keycloak });
    },

  };

  Vue.use(VueKeyCloak, options);

  router.beforeEach(async (to: Route, from: Route, next: any) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      // We wait for Keycloak init, then we can call all methods safely
      const keycloak = router.app.$keycloak;

      while (!keycloak.createLoginUrl?.()) {
        log.debug('Keycloak is not ready. Doing a short sleep');
        await sleep(100);
      }

      if (keycloak.authenticated) {
        log.debug('Keycloak is authenticated');
        next();
      } else {
        const loginUrl = keycloak.createLoginUrl() ?? '/';
        window.location.replace(loginUrl);
      }
    } else {
      next();
    }
  });
}
