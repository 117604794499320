import { VersionApi } from '@/api/versionApi';

const state = {
  runningAssembly: '-',
  versionInfo: null,
  gitInfo: null,
  environmentName: '-'
};

const getters = {
  runningAssembly(state) {
    return state.runningAssembly;
  },
  versionInfo(state) {
    return state.versionInfo;
  },
  gitInfo(state) {
    return state.gitInfo;
  }
};

const mutations = {
  versionChanged(state, data) {
    state.runningAssembly = data.runningAssembly;
    state.gitInfo = data.gitInfo;
    state.versionInfo = data.versionInfo;
    state.environmentName = data.environmentName;
  }
};

const actions = {
  async getVersion(context) {
    const { data } = await VersionApi.getVersion();
    context.commit('versionChanged', data);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
