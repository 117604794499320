import Vue from 'vue';
import errorHelper from '@/app/errorHandler';

export function formatSize(size) {

  if (typeof size !== 'number' || Number.isNaN(size) === true) {
    return 'unknown size';
  }

  if (size <= 0) {
    return '0 bytes';
  }

  const abbreviations = ['bytes', 'KiB', 'MiB', 'GiB'];
  const index = Math.floor(Math.log(size) / Math.log(1024));

  return `${+(size / Math.pow(1024, index)).toPrecision(3)} ${
    abbreviations[index]
  }`;
}

/* @Component
export default class GlobalMixins {

} */

export default function configureGlobalMixins() {
  Vue.mixin({
    methods: {
      handleError(e) {
        errorHelper(e);
      },
      getInputValidationState(errors) {
        if (errors && errors[0]) {
          return false;
        }
        return null;
      },
      isInputInvalid(errors) {
        return !!(errors && errors[0]);
      },
      stringToBytes(str) {
        const bytes = new Uint8Array(str.length);
        for (let i = 0; i < str.length; i++) {
          bytes[i] = str.charCodeAt(i);
        }
        return bytes;
      },
      showBetaFeature() {
        const roles = this.$store.state.userStore.roles;
        const idx = roles.indexOf('beta_features_visible');
        return idx > -1;
      },
      isUserInRole(desiredRoles) {

        if (!this.$keycloak.authenticated) {
          return false;
        }

        console.log({ desiredRoles });
        for (let i = 0; i < desiredRoles.length; i++) {

          const roleName = desiredRoles[i];
          if (this.$keycloak.hasResourceRole(roleName)) {
            return true;
          }

          if (this.$keycloak.hasResourceRole('_' + roleName)) {
            return true;
          }
        }

        return false;
        // debugger;
        // const roles = this.$store.state.userStore.roles;
        // const idx = this._.findIndex(desiredRoles, dr => {
        //   return roles.findIndex(r => r === dr) > -1;
        // });
        // return idx > -1;
      },
      capitalize(str) {
        return this._.capitalize(str);
      },

      formatSize(value) {
        return formatSize(value);
      },
    },
  });
}
