import Vue from 'vue';
import Router from 'vue-router';
import adminRoutes from '@/router/adminRoutes';
import publicRoutes from '@/router/publicRoutes';
import resignationRoutes from '@/router/resignationRoutes';
import authRoutes from '@/router/authRoutes';
import eventRoutes from '@/router/eventRoutes';

Vue.use(Router);

const { BASE_URL } = process.env;

const router = new Router({
  mode: 'history',
  base: BASE_URL,
  routes: [
    ...publicRoutes,
    ...resignationRoutes,
    ...adminRoutes,
    ...authRoutes,
    ...eventRoutes
  ]
});

router.afterEach((to, from) => {

  document.title = to.meta?.title || 'Quitmormon';
});

export default router;
