import { AUTH_AUDIENCE, AUTH_REALM } from '@/api/frontEndSettingsApi';
import { frontEndSettingsKey } from '@/app/constants';

const state = {
  authority: null,
  host: null,
  realm: AUTH_REALM,
  audience: AUTH_AUDIENCE,
  accountManagementUrl: null,
  customersUrl: null,
  settingsLoadedDate: new Date(0),

  maxEmailAttachmentMb: 10,
  maxSingleAttachmentMb: 10
};

const getters = {
  isValid: state => {
    return isValid(state);
  },
  isExpired: state => {
    state.settingsLoadedDate = Date.parse(state.settingsLoadedDate);

    const settingsAge = new Date() - state.settingsLoadedDate;
    return settingsAge > 60 * 1000 * 10;
  }
};

function isValid(settings) {
  return !!(settings && settings.authority && settings.host && settings.realm && settings.audience && settings.accountManagementUrl);
}

const mutations = {
  updateSettings(state, settings) {
    state.authority = settings.uiAuthority;
    state.host = settings.uiHost;
    state.realm = settings.realm;
    state.audience = settings.audience;
    state.accountManagementUrl = settings.uiAccountManagementUrl;
    state.customersUrl = settings.uiCustomersUrl;
    state.maxEmailAttachmentMb = settings.maxEmailAttachmentMb;
    state.maxSingleAttachmentMb = settings.maxSingleAttachmentMb;
    state.settingsLoadedDate = new Date();
  }
};

const actions = {
  loadFrontEndSettingsFromLocalStorage(context) {
    const state = context.state;

    // try to load front-end settings from local storage
    const frontEndSettings = localStorage.getItem(frontEndSettingsKey);

    // if found, assign it to the store
    // make sure it also has authority property
    if (frontEndSettings) {
      const frontEndSettingsObj = JSON.parse(frontEndSettings);

      if (isValid(frontEndSettingsObj)) {
        Object.assign(state, frontEndSettingsObj);

        console.log('INIT: Loaded front end settings from local storage');
      }
    }
  },
  saveFrontEndSettingsToLocalStorage(context) {
    const state = context.state;
    if (isValid(state)) {
      localStorage.setItem(frontEndSettingsKey, JSON.stringify(state));
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
