import axois from 'axios';
import { ROOT_URL, API_VER } from '@/app/constants';

const API_CONTROLLER = 'version';

export class VersionApi {
  static getVersion() {
    const url = `${ROOT_URL}/${API_VER}/${API_CONTROLLER}`;
    return axois.get(url);
  }
}
