import Vue from 'vue';

// Izi Toast
import VueIziToast from 'vue-izitoast';
import 'izitoast/dist/css/iziToast.min.css';

// Set up SweetAlert
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

export default function configureAlerts() {

  Vue.use(VueSweetalert2, {
    reverseButtons: true,
    showClass: {
      popup: 'swal2-noanimation',
      backdrop: 'swal2-backdrop-show'
    }
  });

  Vue.use(VueIziToast, {
    position: 'topRight',
    transitionIn: 'flipInX',
    transitionOut: 'flipOutX',
    closeOnEscape: true,
    animateInside: false,
    timeout: 2000
  });
}
