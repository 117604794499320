<template>
  <v-footer app>

    <v-row>
      <v-col>
        <span v-if="!isLoggedIn">
          You are not logged in
        </span>
        <span v-if="isLoggedIn">
          User: {{ userName }}
        </span>
      </v-col>
      <v-col class="text-right">
        <span class="text-muted">&copy; QuitMormon.com {{ copyrightYear }}</span>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'AppFooter',
  computed: {
    isLoggedIn() {
      if (this.$oidc) {
        return this.$oidc.isAuthenticated;
      }
      return false;
    },
    userName() {
      if (this.$oidc.isAuthenticated) {
        return this.$store.getters['user/name'];
      }
      return '-';
    },
    copyrightYear() {
      const currentYear = new Date().getFullYear();
      return currentYear === 2018 ? '2018' : `2018 - ${currentYear}`;
    },
    ...mapState({
      versionStore: state => state.versionStore
    })
  },
  methods: {
    showVersionInfo() {
      this.$store.dispatch('version/getVersion');
      this.$bvModal.show('app-version-modal');
    }
  }
};
</script>

<style scoped>
.footer {

  padding: 0 8px;
  height: 60px; /* Set the fixed height of the footer here */
  line-height: 60px; /* Vertically center the text there */
  background-color: #f5f5f5;
  z-index: 1000;
  overflow: hidden;
}
</style>
