

































































































































import Component, { mixins } from 'vue-class-component';
import RoleRestricted from '@/components/RoleRestricted.vue';
import userStoreMixin from '@/mixins/userStoreMixin';

@Component({
  components: { RoleRestricted },
})
export default class MainNav extends mixins(userStoreMixin) {

  get accountManagementUrl() {
    return this.$keycloak.createAccountUrl?.();
  }

  get isLoggedIn() {
    return this.$keycloak.authenticated;
  }

  async signOut() {
    await this.$keycloak.logoutFn?.();
    localStorage.clear();
  }

}
