import axios from 'axios';
import { ROOT_URL, API_VER } from '@/app/constants';

const API_CONTROLLER = 'meta/front-end-settings';
export const AUTH_REALM = process.env.VUE_APP_AUTH_REALM;
export const AUTH_AUDIENCE = process.env.VUE_APP_AUTH_AUDIENCE;

export class FrontEndSettingsApi {
  static getFrontEndSettings() {
    const url = `${ROOT_URL}/${API_VER}/${API_CONTROLLER}`;
    return axios.get(url);
  }
}
