import Vue from 'vue';
import log from 'loglevel';

export { log };

export function configureLogging() {
  debugger;
  log.setLevel(log.levels.DEBUG);
  log.getLogger('auth').setLevel(log.levels.DEBUG);
  Vue.prototype.$log = log;
}

export const logOidc = log.getLogger('OIDC').setLevel(log.levels.DEBUG);

