import { AdminResignationQueueApi } from '@/api/adminResignationQueueApi';
import handleError from '@/app/errorHandler';
import { SearchPage } from '@/interfaces/searchPage';
import { IResignationView, ResignationQueueStatus, IResignationQueueSearchRequest } from '@/interfaces/_auto/api';
import { Commit } from 'vuex';


import { PagedResult } from '@/interfaces/pagedResult';

export interface IAdminResignationStoreSearch {
  searchPage: SearchPage;
  searchTerm: string | null;
  holdFlag: boolean | null;
  duplicateFlag: boolean | null;
  errorFlag: boolean | null;
  hasApprovedDocuments: boolean | null;
  resignationQueueStatus: ResignationQueueStatus | null,
}

export interface IAdminResignationStoreState {
  search: IAdminResignationStoreSearch,

  totalRecords: number,
  resignationListLoading: boolean;
  resignationList: IResignationView[];
  currentAdminIndex: number;
}

const state: IAdminResignationStoreState = {

  search: {
    searchPage: new SearchPage(),
    searchTerm: null,
    resignationQueueStatus: null,
    holdFlag: false,
    duplicateFlag: false,
    errorFlag: false,
    hasApprovedDocuments: null,
  },

  totalRecords: 0,
  resignationListLoading: false,
  resignationList: [],

  currentAdminIndex: 0,
};

const getters = {};

const mutations = {
  saveResignationSearchResults(state: IAdminResignationStoreState, payload: PagedResult<IResignationView>): void {
    const { records, totalRecords } = payload;
    state.totalRecords = totalRecords;
    state.resignationList = records;
  },

  savePage(state, payload) {
    state.searchPage.currentPage = payload;
    state.searchPage.pageIndex = payload - 1;
  },

  changeSearch({ search }, newSearch: never) {
    Object.assign(search, newSearch);
  },

  changePage({ search }: IAdminResignationStoreState, searchPage: SearchPage) {
    Object.assign(search.searchPage, searchPage);
  },

  saveCurrentAdminIndex(state, payload) {
    state.currentAdminIndex = payload;
  },
};

export function buildResignationQueueSearch(search) {
  return {
    searchPage: {
      pageIndex: search.searchPage.currentPage - 1,
      pageSize: search.searchPage.pageSize,
    },
    searchTerm: search.searchTerm,
    resignationQueueStatus: search.resignationQueueStatus,
    holdFlag: search.holdFlag,
    duplicateFlag: search.duplicateFlag,
    errorFlag: search.errorFlag,
    hasApprovedDocuments: search.hasApprovedDocuments,
  } as IResignationQueueSearchRequest;
}

const actions = {
  async searchResignationQueue({ commit, state }: { commit: Commit, state: IAdminResignationStoreState }) {

    const search = state.search;

    const resignationQueueSearchRequest = buildResignationQueueSearch(search);

    console.log('Searching resignation queue', resignationQueueSearchRequest);

    try {
      state.resignationListLoading = true;
      const { data } = await AdminResignationQueueApi.searchResignationQueue(resignationQueueSearchRequest);
      commit('saveResignationSearchResults', data);
    } catch (e) {
      console.error(e);
      handleError(e);
    } finally {
      state.resignationListLoading = false;
    }
  },

  async pageChanged({ commit, dispatch }, currentPage) {
    commit('savePage', currentPage);
    await dispatch('searchResignationQueue');
  },
};

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions,
};
