export default [
  {
    path: '/auth/signed-out',
    name: 'signed-out',
    component: () => import('@/views/Auth/SignedOut'),
    meta: {}
  },
  {
    path: '/sign-in',
    name: 'sign-in',
    component: () => import('@/views/Auth/SignIn'),
    meta: {
      // requires auth because this will trigger key cloak authentication
      requiresAuth: true
    }
  },
  {
    path: '/auth/create-account',
    name: 'create-account',
    component: () => import('@/views/Auth/CreateAccount'),
    meta: {
      // requires auth because this will trigger key cloak authentication
      requiresAuth: true
    }
  },

];
