// font awesome
import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { fas } from '@fortawesome/free-solid-svg-icons';

export default function configureFontAwesome() {
  // set up the font awesome library
  library.add(fas);
  Vue.component('FontAwesomeIcon', FontAwesomeIcon);
}
