import Vue from 'vue';
import App from '@/App.vue';
import store from '@/store';
import router from '@/router';

import 'vue-multiselect/dist/vue-multiselect.min.css';

import configureAlerts from '@/app/alerts';
import configureFontAwesome from '@/app/fontawesome';

import VueMoment from 'vue-moment';
import { vuetify } from '@/setup/plugins/vuetify';
import configureValidation from '@/main.validation';
import numeral from 'numeral';
import Loader from '@/components/Loader.vue';
import { Multiselect } from 'vue-multiselect';
import { configureGlobals } from '@/app/global-imports';
import configureGlobalMixins, { formatSize } from '@/app/global-mixins.js';
import configureSentry from '@/app/sentry';
import { keycloakOfficialAuth } from '@/oidc';

// components
Vue.component('Multiselect', Multiselect);
Vue.component('Loader', Loader);

Vue.config.productionTip = false;

// global components
Vue.use(VueMoment);

Vue.filter('formatNumber', function(value) {
  return numeral(value).format('0,0'); // displaying other groupings/separators is possible, look at the docs
});

Vue.filter('formatSize', function(value) {
  return formatSize(value); // displaying other groupings/separators is possible, look at the docs
});

configureSentry();
configureGlobalMixins();
configureGlobals();
configureFontAwesome();
configureValidation();
configureAlerts();

keycloakOfficialAuth();

export const vue = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
