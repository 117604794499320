import { UserApi } from '@/api/userApi';

const state = {
  user: {
    userId: null,
    username: null,
    email: null,
    firstName: null,
    lastName: null,
    displayName: null
  },
  roles: []
};

const getters = {
  name: state => {
    return '';
    // if (state.user.displayName) {
    //   return state.user.displayName;
    // } else if (oidc.userProfile.name) {
    //   return oidc.userProfile.name;
    // } else {
    //   return 'No User';
    // }
  }
};

const mutations = {
  saveUser(state, payload) {
    const { user, roles } = payload;
    Object.assign(state.user, user);
    state.roles = roles;
  },
  clearUser(state) {
    state.user = {
      userId: null,
      username: '',
      email: '',
      firstName: '',
      lastName: '',
      displayName: 'No User'
    };
    state.roles = [];
  }
};

const actions = {
  async getUserProfile({ commit }) {
    console.log('Getting user profile');
    const { data } = await UserApi.getUserProfile();
    commit('saveUser', data);
  }
};

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions
};
