<template>
  <span class="role-restricted "
        v-if="show()">
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: 'RoleRestricted',
  props: {
    roles: {
      type: [String, Array],
      required: true
    }
  },
  methods: {
    show() {
      let rolesArray = [];

      if (typeof (this.roles) === 'string') {
        rolesArray.push(this.roles);
      } else {
        rolesArray = this.roles;
      }

      return this.isUserInRole(rolesArray);
    }
  }
};
</script>

<style scoped>

</style>
