import Vue from 'vue';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, min, max } from 'vee-validate/dist/rules';
import moment from 'moment';

export default function configureValidation() {
  Vue.component('ValidationProvider', ValidationProvider);
  Vue.component('ValidationObserver', ValidationObserver);

  extend('required', {
    ...required,
    message: '{_field_} is required'
  });
  extend('min', min);
  extend('max', max);


  extend('isTrue', {
    validate: (value) => {
      return value === true;
    }
  });

  extend('birthYear', {
    validate: (year) => {
      return (year >= 1900) && (year <= new Date().getFullYear());
    },
    message: `Year must be between 1900 and ${new Date().getFullYear()}`
  });

  extend('birthDate', {
    validate: (dayOfMonth, { dateParts }) => {

      const { year, month } = dateParts;

      if (dayOfMonth < 1) {
        return false;
      } else if (dayOfMonth > 31) {
        return false;
      }

      if (year && month) {
        const daysInMonth = moment(new Date(year, month, 1)).daysInMonth();
        return dayOfMonth <= daysInMonth;
      }

      return true;
    },
    castValue: toInt,
    message: 'Birth Day is invalid',
    params: ['dateParts']
  });

  function toInt(value) {
    return Number.parseInt(value);
  }
}
