import Vue from 'vue';
// global css
import '@/theme/Main.scss';
import '@/theme/resignation.scss';
// lodash
import VueLodash from 'vue-lodash';
import { chain, debounce, filter, find, findIndex, get, map, orderBy, shuffle, sortBy, sumBy, throttle, uniqBy } from 'lodash';
// Bootstrap
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

export function configureGlobals() {

  // Bootstrap
  Vue.use(BootstrapVue);

  // lodash
  Vue.use(VueLodash, {
    lodash: {
      chain,
      debounce,
      throttle,
      find,
      findIndex,
      get,
      map,
      orderBy,
      filter,
      uniqBy,
      sortBy,
      sumBy,
      shuffle
    }
  });

}
