<template>
  <v-app>
    <v-main>
      <main-nav v-if="showMainNav" />
      <router-view id="router-view"
                   :key="$route.fullPath" />
      <app-footer v-if="showMainNav" />
    </v-main>
  </v-app>
</template>
<script>
import MainNav from '@/components/MainNav';
import AppFooter from '@/components/AppFooter';

export default {
  name: 'App',
  components: { AppFooter, MainNav },
  data() {
    return {};
  },
  computed: {
    showMainNav() {
      return !window.location.pathname.startsWith('/admin-external');
    },
  },
};
</script>

<style lang="scss">

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

// Change default form invalid styles
.form-control.is-invalid {
  border-width: 2px;
}

.multiselect.is-invalid {
  border: 2px solid red;
  border-radius: 5px;
}

#router-view {
  // view minus footer minus header minus padding
  min-height: calc(100vh - 60px - 80px - 24px);
}

.body-container {
  margin-top: 24px;
}

.fa-angle-right:hover {
  cursor: pointer;
}

.icon-link {
  margin-left: 7px;
  color: #007bff;

  &:hover {
    cursor: pointer;
  }
}

.field-required > label:after {
  content: ' *';
  color: #f00;
}

a.nav-link:hover {
  cursor: default;
}

tr.b-table-details:hover {
  background-color: #fff !important;
}

.no-wrap {
  white-space: nowrap;
}

/* Body formatting for sweet alert2 error messages */
.error-message-body {
  text-align: left;

  h3 {
    font-size: 1.2rem;
  }

  .error-message {

  }

  .error-property {
    font-family: monospace;
    color: #1e2b37;
  }
}
</style>
