import { authAxios } from '@/api';
import { API_VER, ROOT_URL } from '@/app/constants';
import { IResignationQueueSearchRequest, IResignationView } from '@/interfaces/_auto/api';
import { AxiosPromise } from 'axios';
import { PagedResult } from '@/interfaces/pagedResult';

const API_CONTROLLER = 'admin/resignation-queue';

export class AdminResignationQueueApi {

  static searchResignationQueue(resignationQueueSearchRequest: IResignationQueueSearchRequest): AxiosPromise<PagedResult<IResignationView>> {
    const url = `${ROOT_URL}/${API_VER}/${API_CONTROLLER}/search`;
    return authAxios.post(url, resignationQueueSearchRequest);
  }

  static getAdminSummary() {
    const url = `${ROOT_URL}/${API_VER}/${API_CONTROLLER}/summary`;
    return authAxios.get(url);
  }

}
