import { EnumApi } from '@/api';

const state = {
  resignationEnums: {
    genderTypeOptions: [],
  },
  adminEnums: {
    resignationQueueStatusOptions: [],
  },
  churchAdminEnums: {
    churchResignationStatusOptions: [],
  },
};

const getters = {};

const mutations = {
  updateResignationEnums(state, payload) {
    state.resignationEnums = payload;
  },

  updateAdminEnums(state, payload) {
    state.adminEnums = payload;
  },

  updateChurchAdminEnums(state, payload) {
    state.churchAdminEnums = payload;
  },
};

const actions = {
  async getResignationEnums({ commit }) {
    const { data } = await EnumApi.getResignationEnums();
    commit('updateResignationEnums', data);
    return data;
  },

  async getAdminEnums({ commit }) {
    const { data } = await EnumApi.getAdminEnums();
    commit('updateAdminEnums', data);
    return data;
  },

  async getChurchAdminEnums({ commit }) {
    const { data } = await EnumApi.getChurchAdminEnums();
    commit('updateChurchAdminEnums', data);
    return data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
