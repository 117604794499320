import Component from 'vue-class-component';
import Vue from 'vue';


@Component
export default class userStoreMixin extends Vue {
  resignationId = null;
  resignation = null;
  documents = [];

  userStore() {
    return this.$store.state.userStore;
  }

  get user() {
    return this.$store.state.userStore.user;
  }

  showBetaFeature() {
    const roles = this.$store.state.user.roles;
    const idx = roles.indexOf('beta_features_visible');
    return idx > -1;
  }

  isUserInRole(desiredRoles) {
    const roles = this.$store.state.user.roles;
    const idx = this._.findIndex(desiredRoles, dr => {
      return roles.findIndex(r => r === dr) > -1;
    });
    return idx > -1;
  }
}
