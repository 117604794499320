import Vue from 'vue';
import store from '@/store';
import router from '@/router';
import * as Integrations from '@sentry/integrations';
import * as Sentry from '@sentry/browser';
import { SENTRY_DSN } from '@/app/constants';


export default function configureSentry() {

  console.log('Running in environment: ' + process.env.NODE_ENV);

  if (process.env.NODE_ENV !== 'development') {
    console.log('Adding Sentry reporting');

    Sentry.init({
      dsn: SENTRY_DSN,
      integrations: [new Integrations.Vue({ Vue, attachProps: true })],
      release: store.state.version.runningAssembly,
      environment: store.state.version.environmentName,
      beforeSend(event, hint) {
        console.log('Sentry error', event, hint);

        if (event.exception) {
          Sentry.showReportDialog({
            title: 'Unfortunately, something went wrong.',
            labelSubmit: 'Send feedback',
            eventId: event.event_id
          });
        }
        return event;
      }
    });

    /*
    router.beforeEach((to, from, next) => {

      if (oidc && oidc.userProfile) {

        try {
          const userEmail = oidc.userProfile.email;
          const userId = oidc.userProfile.sub;

          Sentry.configureScope(scope => {
            scope.setUser({
              id: userId,
              email: userEmail
            });
          });
        } catch (e) {
          console.log('Failed to set user in Sentry scope');
        }
      }

      next();
    });
*/
  }
}
