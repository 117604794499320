export default [
  {
    path: '/resignations',
    name: 'resignation-list',
    component: () => import('@/views/User/ResignationList'),
    meta: {
      requiresAuth: true,
      title: 'Resignations'
    }
  },
  {
    path: '/resignation/new',
    name: 'resignation-new',
    component: () => import('@/views/User/ResignationEdit'),
    meta: {
      requiresAuth: true,
      title: 'New Resignation'
    }
  },
  {
    path: '/resignation/:resignationId',
    name: 'resignation-edit',
    component: () => import('@/views/User/ResignationEdit'),
    meta: {
      requiresAuth: true,
      title: 'Edit Resignation'
    }
  },
  {
    path: '/resignation/:resignationId/pending',
    name: 'resignation-pending',
    component: () => import('@/views/User/ResignationDetail'),
    meta: {
      requiresAuth: true,
      title: 'Resignation Details'
    }
  },
  {
    path: '/resignation/:resignationId/errors',
    name: 'resignation-errors',
    component: () => import('@/views/User/ResignationErrors'),
    meta: {
      requiresAuth: true,
      title: 'Resignation Errors'
    }
  }
];
