import Vue from 'vue';
import Vuex from 'vuex';
import createCache from 'vuex-cache';

import user from '@/store/modules/userStore';
import frontEndSettings from '@/store/modules/frontEndSettings';
import version from '@/store/modules/versionStore';
import enums from '@/store/modules/enums';
import adminResignationStore from '@/store/modules/adminResignationStore.ts';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createCache()],
  modules: {

    user,
    userStore: user,

    frontEndSettings,

    version,
    versionStore: version,

    enums,
    adminResignationStore
  }
});
