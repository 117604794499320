import axios, { AxiosInstance } from 'axios';
import { VueKeycloakInstance } from '@dsb-norge/vue-keycloak-js/dist/types';
import { log as l } from '@/setup/logging';
import router from '@/router';

export { FrontEndSettingsApi } from '@/api/frontEndSettingsApi';
export { EnumApi } from '@/api/enumApi';
export { UserApi } from '@/api/userApi';
export { VersionApi } from '@/api/versionApi';

const log = l.getLogger('auth');
export const authAxios: AxiosInstance = axios.create();

log.debug('Configuring axios interceptor...');

authAxios.interceptors.request.use(async (config) => {

  const { authenticated, token }: VueKeycloakInstance = router.app.$keycloak;

  log.debug('Axios interceptor', { authenticated });

  if (authenticated) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, err => {
  return Promise.reject(err);
});
/*

authAxios.interceptors.request.use(config => {

  try {
    if (oidc && oidc.isAuthenticated && oidc.accessToken) {
      config.headers.Authorization = `Bearer ${oidc.accessToken}`;
    }
  } catch (e) {
    console.error('Failed to add access token', e);
    throw e;
  }

  return config;
}, err => {
  return Promise.reject(err);
});
*/
