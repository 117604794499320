export class SearchPage {
  pageIndex?: number;
  pageSize: number;

  private _currentPage!: number;

  get currentPage(): number {
    return this._currentPage;
  }

  set currentPage(value: number) {
    this.pageIndex = value - 1;
    this._currentPage = value;
  }

  constructor(pageSize = 20, currentPage = 1) {
    this.pageSize = pageSize;
    this.currentPage = currentPage;
  }
}

