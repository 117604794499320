export default [

  {
    path: '/admin/resignation-queue',
    name: 'admin-resignation-queue',
    component: () => import('@/views/Admin/ResignationQueue'),
    meta: {
      requiresAuth: true,
      title: 'Resignation Queue'
    }
  },
  {
    path: '/admin/resignation/:resignationId',
    name: 'admin-resignation-detail',
    component: () => import('@/views/Admin/AdminResignationDetail'),
    meta: {
      requiresAuth: true,
      title: 'Resignation Detail'
    },
    props: (router) => ({
      resignationId: +router.params.resignationId,
      position: +router.query.position
    })
  },

  {
    path: '/admin/events/',
    name: 'admin-event-list',
    component: () => import('@/views/Admin/AdminEvents/EventsList'),
    meta: {
      requiresAuth: true,
      title: 'Resignation Detail'
    }
  }
];
