import { authAxios } from '@/api';
import { ROOT_URL, API_VER } from '@/app/constants';

const API_CONTROLLER = 'enum';

export class EnumApi {
  static getResignationEnums() {
    const url = `${ROOT_URL}/${API_VER}/${API_CONTROLLER}/resignation-enums`;
    return authAxios.get(url);
  }
  static getAdminEnums() {
    const url = `${ROOT_URL}/${API_VER}/${API_CONTROLLER}/admin-enums`;
    return authAxios.get(url);
  }
  static getChurchAdminEnums() {
    const url = `${ROOT_URL}/${API_VER}/${API_CONTROLLER}/church-admin-enums`;
    return authAxios.get(url);
  }
}
